import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Arrow from './button/arrow';

const Container = styled.div<{ width: number; z: number }>`
  width: ${p => p.width}px;
  display: relative;
  z-index: ${p => p.z};
  box-sizing: border-box;
`;

const Text = styled.p<{ altStyle: boolean }>`
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: ${p => (p.altStyle ? p.theme.colour.white : p.theme.colour.grey5)};
`;

const DropText = styled.p<{ selected: boolean; header: boolean }>`
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: ${p =>
    p.header ? 'white' : p.selected ? 'white' : p.theme.colour.grey5};
`;

const TypeButton = styled.div<{ menuOpen: boolean; outline: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  :hover ${Text} {
    color: ${p => p.theme.colour.primary};
  }
`;

const DropDown = styled.div<{
  width: number;
  offsetHeight: string;
  offsetWidth: string;
}>`
  position: absolute;
  width: ${p => p.width}px;
  transform: ${p => `translate(${p.offsetWidth}, ${p.offsetHeight})`};
  border-radius: 0 0 0 5px;
  overflow: hidden;
  box-sizing: border-box;
`;

const DropCell = styled.div<{
  selected: boolean;
  outline: boolean;
  header: boolean;
}>`
  height: 35px;
  background-color: ${p =>
    p.header
      ? p.theme.colour.grey5
      : p.selected
      ? p.theme.colour.accent
      : '#FAFAFA'};
  display: flex;
  justify-content: ${p => (p.outline ? 'center' : 'flex-end')};
  align-items: center;
  cursor: pointer;
  user-select: none;
  :nth-child(even) {
    background-color: ${p =>
      p.header
        ? p.theme.colour.grey4
        : p.selected
        ? p.theme.colour.accent2
        : '#F5F5F5'};
  }
  :hover {
    background-color: ${p =>
      p.header ? p.theme.colour.grey3 : p.theme.colour.secondary};
  }
  :hover ${DropText} {
    color: white;
  }
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
`;

type Props = {
  menuItems: string[];
  onSelectActive: (items: string[]) => void;
  selectMultiple?: boolean;
  heading?: string;
  width?: number;
  offsetHeight?: string;
  offsetWidth?: string;
  outline?: boolean;
  closeOnClick?: boolean;
  selectable?: boolean;
  zindex?: number;
  altStyle?: boolean;
  header?: boolean;
};

const DropdownMenu: FC<Props> = ({
  menuItems,
  onSelectActive,
  selectMultiple,
  heading,
  width,
  offsetHeight = '0px',
  offsetWidth = '0px',
  outline = true,
  closeOnClick = false,
  selectable = true,
  zindex = 5,
  altStyle = false,
  header = false
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [multipleItems, setMultipleItems] = useState<string[]>([]);
  const ref = useRef(null);

  useEffect(() => {
    setActiveItem(menuItems[0]);
  }, []);

  const clickListener = (event: Event) => {
    let target = event.target as HTMLElement;
    if (multipleItems) {
      let className = target.className.slice(0, 8);
      if (className !== 'dropdown') {
        document.removeEventListener('click', clickListener);
        setMenuOpen(false);
      }
    } else {
      let button = ref.current! as HTMLDivElement;
      if (event.target !== button) {
        document.removeEventListener('click', clickListener);
        setMenuOpen(false);
      }
    }
  };

  const open = () => {
    if (!menuOpen) {
      document.addEventListener('click', clickListener);
    } else {
      document.removeEventListener('click', clickListener);
    }
    setMenuOpen(!menuOpen);
  };

  const itemClick = (event: React.MouseEvent<HTMLDivElement>, item: string) => {
    if (selectMultiple) {
      event.stopPropagation();
      let index = multipleItems.findIndex(x => x === item);
      let newItems: string[] = [...multipleItems];
      if (index === -1) {
        newItems.push(item);
      } else {
        newItems.splice(index, 1);
      }
      setMultipleItems(newItems);
      onSelectActive(newItems);
    } else {
      setActiveItem(item);
      onSelectActive([item]);
    }
    if (closeOnClick) {
      setMenuOpen(false);
    }
  };

  const checkIfSelected = (item: string): boolean => {
    if (selectMultiple) {
      return multipleItems.findIndex(x => x === item) === -1 ? false : true;
    } else {
      return activeItem === item ? true : false;
    }
  };

  return (
    <Container width={width ? width : 100} z={zindex}>
      <TypeButton
        onClick={open}
        menuOpen={menuOpen}
        ref={ref}
        outline={outline}
      >
        <Text altStyle={altStyle}>
          {heading ? `${heading}` : `${activeItem}`}
        </Text>
        <Arrow active={menuOpen} />
      </TypeButton>
      {menuOpen && (
        <DropDown
          offsetHeight={offsetHeight}
          offsetWidth={offsetWidth}
          className={'dropdown'}
          width={width ? width : 100}
        >
          {menuItems.map((item, index) => (
            <DropCell
              outline={outline}
              className={'dropdown'}
              onClick={event => itemClick(event, item)}
              key={`menuItem${index}`}
              selected={selectable && checkIfSelected(item)}
              header={header}
            >
              <DropText
                selected={selectable && checkIfSelected(item)}
                header={header}
              >
                {item}
              </DropText>
            </DropCell>
          ))}
        </DropDown>
      )}
    </Container>
  );
};

export default DropdownMenu;
