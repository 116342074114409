import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  > p {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  background-color: ${p => p.theme.colour.primary};
  :hover {
    background-color: ${p => p.theme.colour.primaryLight};
  }
  :active {
    background-color: ${p => p.theme.colour.secondary};
  }
  > p {
    color: white;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: ${p => p.theme.colour.secondary};
  :hover {
    background-color: ${p => p.theme.colour.secondaryLight};
  }
  :active {
    background-color: ${p => p.theme.colour.primary};
  }
  > p {
    color: white;
  }
`;

const PrimaryOutline = styled(ButtonBase)`
  background-color: transparent;
  border: 0.5px solid ${p => p.theme.colour.primary};
  :hover {
    background-color: ${p => p.theme.colour.grey1};
  }
  :active {
    background-color: ${p => p.theme.colour.grey2};
  }
  > p {
    color: ${p => p.theme.colour.primary};
  }
`;

const SecondaryOutline = styled(ButtonBase)`
  background-color: transparent;
  border: 0.5px solid ${p => p.theme.colour.secondary};
  :hover {
    background-color: ${p => p.theme.colour.grey1};
  }
  :active {
    background-color: ${p => p.theme.colour.grey2};
  }
  > p {
    color: ${p => p.theme.colour.secondary};
  }
`;

type Props = {
  width?: string;
  onClick: () => void;
  style?: 'primary' | 'secondary' | 'primaryOutline' | 'secondaryOutline';
  children: any;
};

const JobsGrid: FC<Props> = ({
  onClick,
  width = 'auto',
  style = 'primary',
  children
}) => {
  return (
    <React.Fragment>
      {style === 'primary' && (
        <PrimaryButton onClick={onClick} style={{ width: width }}>
          <p>{children}</p>
        </PrimaryButton>
      )}
      {style === 'secondary' && (
        <SecondaryButton onClick={onClick} style={{ width: width }}>
          <p>{children}</p>
        </SecondaryButton>
      )}
      {style === 'primaryOutline' && (
        <PrimaryOutline onClick={onClick} style={{ width: width }}>
          <p>{children}</p>
        </PrimaryOutline>
      )}
      {style === 'secondaryOutline' && (
        <SecondaryOutline onClick={onClick} style={{ width: width }}>
          <p>{children}</p>
        </SecondaryOutline>
      )}
    </React.Fragment>
  );
};

export default JobsGrid;
