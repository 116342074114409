import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ touching: boolean }>`
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p =>
    p.touching ? p.theme.colour.secondary : p.theme.colour.primaryDark};
  border-radius: 5px;
  > p {
    text-align: center;
    font-size: ${p => p.theme.fontSize.body};
    color: white;
    pointer-events: none;
    user-select: none;
  }
`;

type Props = {
  width?: string;
  onClick?(): void;
  children: any;
};

const Component: FC<Props> = ({ children, width = '100%', onClick }) => {
  const [touching, setTouching] = useState(false);

  const click = () => {
    setTouching(false);
    if (onClick) onClick();
  };
  return (
    <Container
      style={{ width: width }}
      onTouchStart={() => setTouching(true)}
      onTouchEnd={() => setTouching(false)}
      onClick={click}
      touching={touching}
    >
      <p>{children}</p>
    </Container>
  );
};

export default Component;
