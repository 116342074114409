import React, { FC } from 'react'
import styled from 'styled-components'

const Circle = styled.circle`
  fill: ${p => p.theme.colour.primary};
`

const Line = styled.line`
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-linecap: round;
`

const PolyLine = styled.polyline`
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-linecap: round;
`

const SVG = styled.svg`
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  :hover ${Circle} {
    fill: ${p => p.theme.colour.primaryDark};
  }
  :active ${Circle} {
    fill: ${p => p.theme.colour.secondary};
  }
`

type Props = {
  onClick(): void
  type: 'cross' | 'leftArrow' | 'rightArrow'
}

const cross = (
  <g>
    <Line x1="13" y1="13" x2="37" y2="37" />
    <Line x1="37" y1="13" x2="13" y2="37" />
  </g>
)

const left = <PolyLine points="21 13 33 25 21 37 33 25" />

const right = (
  <g>
    <PolyLine points="29 37 17 25 29 13 17 25" />
  </g>
)

export const GalleryIcon: FC<Props> = ({ onClick, type }) => {
  const click = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    onClick()
  }
  return (
    <SVG viewBox="0 0 50 50" onClick={click}>
      <Circle cx="25" cy="25" r="25" />
      {type === 'cross' && cross}
      {type === 'leftArrow' && left}
      {type === 'rightArrow' && right}
    </SVG>
  )
}
