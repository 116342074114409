import React, { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import lottie, { AnimationItem } from 'lottie-web';

const ArrowUp = require('../../images/JSON/arrowUp.json');
const ArrowDown = require('../../images/JSON/arrowDown.json');

const SVGContainer = styled.svg`
  height: 100%;
  width: auto;
  pointer-events: none;
`;

const Container = styled.div`
  margin-left: 10px;
  height: 60%;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

type Props = {
  active: boolean;
};

const Laurel: FC<Props> = ({ active }) => {
  const arrowRef = useRef(null);
  const [arrowAnim, setArrowAnim] = useState<AnimationItem | undefined>(
    undefined
  );

  useEffect(() => {
    setArrowAnim(
      lottie.loadAnimation({
        container: arrowRef.current!,
        animationData: ArrowDown,
        loop: false,
        autoplay: true,
        renderer: 'svg'
      })
    );
  }, []);

  useEffect(() => {
    if (arrowAnim) {
      arrowAnim.destroy();
      if (active) {
        setArrowAnim(
          lottie.loadAnimation({
            container: arrowRef.current!,
            animationData: ArrowUp,
            loop: false,
            autoplay: true,
            renderer: 'svg'
          })
        );
      } else {
        setArrowAnim(
          lottie.loadAnimation({
            container: arrowRef.current!,
            animationData: ArrowDown,
            loop: false,
            autoplay: true,
            renderer: 'svg'
          })
        );
      }
    }
  }, [active]);

  return (
    <Container>
      <SVGContainer viewBox={'0 0 200 200'}>
        <g ref={arrowRef} />
      </SVGContainer>
    </Container>
  );
};

export default Laurel;
