import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, navigate } from 'gatsby';
import { useState } from 'react';
import Showreel from '../../showreel';
import Button from '../../button/button_browser';
import scrollToElement from 'scroll-to-element';
import DropdownMenu from '../../dropdownMenu';
import Back from '../../button/back';
import { MenuEnum, header } from '../../../util/models';
import { breakpoint } from '../../../util/constant';
import { deviceSize } from '../../../util/styledComponents';
import { SiteContext } from '../../../context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Container = styled.div`
  position: sticky;
  display: flex;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  align-items: center;
  z-index: 50;
  padding: 0 0 0 10px;
  ${deviceSize.greaterThan(breakpoint.tablet)`
    padding: 0 10px 0 10px;
  `}
  box-sizing: border-box;
  ${deviceSize.lessThan(breakpoint.mobile)`
    display: none;
  `}
`;

const Logo = styled(GatsbyImage)`
  height: 50px;
  width: 50px;
  box-sizing: border-box;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Gap = styled.div`
  width: 20px;
`;

const FlexDivider = styled.div`
  flex-grow: 1;
`;

const ShowreelButton = styled.p`
  user-select: none;
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.body}px;
  padding: 0;
  margin: 0;
  color: ${p => p.theme.colour.grey5};
  :hover {
    color: ${p => p.theme.colour.primary};
  }
  :active {
    color: ${p => p.theme.colour.secondary};
  }
`;

const Icon = styled.span`
  color: ${p => p.theme.colour.grey2};
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  padding: 5px;
  display: block;
  box-sizing: border-box;
  :hover {
    color: ${p => p.theme.colour.primary};
  }
`;

const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.body}px;
  padding: 0;
  margin: 0;
  color: ${p => p.theme.colour.grey5};
  :hover {
    color: ${p => p.theme.colour.primary};
  }
  :active {
    color: ${p => p.theme.colour.secondary};
  }
`;

type Props = {
  backButton?: boolean;
  header: header;
};

const Header: FC<Props> = ({ header, backButton = false }) => {
  const { deviceSize, setScrollTop } = useContext(SiteContext);
  const [reelOpen, setReelOpen] = useState(false);
  const image = getImage(header.logo as any);

  const iconLink = (link: string) => {
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  const clientLoginClick = () => {
    window.open(
      header.client,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  const linkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    target: string
  ) => {
    if (typeof window !== undefined) {
      if (window.location.pathname === '/') {
        e.preventDefault();
        history.pushState(null, '', target);
        scrollToElement(target, {
          offset: 0,
          ease: 'outQuad',
          duration: 1000
        });
      } else {
        setScrollTop(true);
      }
    }
  };

  const menuLinkClick = (target: string) => {
    if (typeof window !== undefined) {
      if (window.location.pathname === '/') {
        history.pushState(null, '', target);
        scrollToElement(target, {
          offset: 0,
          ease: 'outQuad',
          duration: 1000
        });
      } else {
        navigate(target);
      }
    }
  };

  const menuClick = (items: string[]) => {
    switch (items[0]) {
      case 'reel':
        setReelOpen(true);
        return;
      case 'client login':
        clientLoginClick();
        return;
      default:
        menuLinkClick(`#${items[0]}`);
        return;
    }
  };

  const backClick = () => {
    history.back();
    navigate('/');
  };

  return (
    <>
      {reelOpen && (
        <Showreel
          videoLink={header.showreel}
          close={() => setReelOpen(false)}
        />
      )}
      <Container>
        {backButton && <Back onClick={backClick} />}
        <React.Fragment>
          <StyledLink to='/' onClick={e => linkClick(e, '#top')}>
            <Logo image={image!} alt='' />
          </StyledLink>
          <Gap />
          {header.icons.map((icon, index) => (
            <Icon key={`icon${index}`} onClick={() => iconLink(icon.link)}>
              <i className={`${icon.icon}`} />
            </Icon>
          ))}
          <FlexDivider />
          {deviceSize === breakpoint.desktop ? (
            <React.Fragment>
              <MenuWrapper>
                <Gap />
                <ShowreelButton onClick={() => setReelOpen(true)}>
                  reel
                </ShowreelButton>
                <Gap />
                {header.menulabels.map(label => (
                  <>
                    <StyledLink
                      to={`/#${label.anchor}`}
                      onClick={e => linkClick(e, `#${label.anchor}`)}
                    >
                      {label.title}
                    </StyledLink>
                    <Gap />
                  </>
                ))}
              </MenuWrapper>
              <Button
                width={'100px'}
                onClick={clientLoginClick}
                style={'primaryOutline'}
              >
                {'Client Login'}
              </Button>
            </React.Fragment>
          ) : (
            <DropdownMenu
              menuItems={[
                'reel',
                ...header.menulabels.map(label => label.anchor),
                'client login'
              ]}
              onSelectActive={menuClick}
              heading={'Menu'}
              width={120}
              offsetHeight={'25px'}
              outline={true}
              closeOnClick={true}
              selectable={false}
              header={true}
            />
          )}
        </React.Fragment>
      </Container>
    </>
  );
};

export default Header;
