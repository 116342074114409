import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import RPlayer from 'react-player';
import { GalleryIcon } from './button/galleryIcon';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const Icon = styled.p`
  position: fixed;
  top: 10px;
  right: 0;
  text-align: right;
  color: ${p => p.theme.colour.grey2};
  font-size: 40px;
  cursor: pointer;
  margin: 0;
  display: block;
  box-sizing: border-box;
  z-index: 200;
`;

const Player = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ReactPlayer = styled(RPlayer)`
  top: 0;
  left: 0;
`;

type Props = {
  close: () => void;
  videoLink: string;
};

const ShowreelMobile: FC<Props> = ({ close, videoLink }) => {
  const closeWindow = () => {
    close();
  };
  return (
    <React.Fragment>
      <Icon>
        <GalleryIcon type={'cross'} onClick={closeWindow} />
      </Icon>
      <Container onClick={closeWindow}>
        <Player>
          <ReactPlayer
            url={videoLink}
            width={'100%'}
            height={'100%'}
            playing={true}
            controls={true}
          />
        </Player>
      </Container>
    </React.Fragment>
  );
};

export default ShowreelMobile;
