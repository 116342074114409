import React, { FC } from 'react'
import styled from 'styled-components'

const BackButton = styled.svg`
  fill: none;
  stroke: ${p => p.theme.colour.primary};
  :active {
    stroke: ${p => p.theme.colour.accent};
  }
  :hover {
    stroke: ${p => p.theme.colour.secondary};
  }
  width: 50px;
  cursor: pointer;
`

type Props = {
  onClick(): void
}

const Component: FC<Props> = ({ onClick }) => {
  return (
    <BackButton
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 180 150"
      strokeWidth={13}
      strokeMiterlimit={10}
      strokeLinecap={'round'}
      onClick={onClick}
    >
      <polyline points="90 127.7 37.3 75 90 22.3" />
    </BackButton>
  )
}

export default Component
