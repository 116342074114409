import React, { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import BurgerIcon from '../../button/burgerIcon';
import gsap from 'gsap';
import Button from '../../button/menu_mobile';
import { MenuEnum, header, contact } from '../../../util/models';
import { deviceSize } from '../../../util/styledComponents';
import { breakpoint } from '../../../util/constant';
import BackButton from '../../button/back';
import { navigate } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby';
import ShowreelMobile from '../../showreel_mobile';

const Overall = styled.div`
  ${deviceSize.greaterThan(breakpoint.mobile)`
display: none;
`}
  box-sizing: border-box;
`;

const Container = styled.div<{ barVisible: boolean }>`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  align-items: center;
  z-index: 50;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  background-color: ${p =>
    p.barVisible ? p.theme.colour.primary : 'transparent'};
`;

const Menu = styled.div`
  z-index: 49;
  position: fixed;
  background-image: linear-gradient(
    180deg,
    ${p => p.theme.colour.primary},
    ${p => p.theme.colour.primaryDark}
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transform: translateX(500px);
`;

const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  user-select: none;
  width: 100%;
`;

const Gap = styled.div`
  height: 10px;
`;

const Icon = styled.p`
  color: ${p => p.theme.colour.grey2};
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  padding: 5px;
  display: block;
  box-sizing: border-box;
`;

const Icons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TextContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${p => p.theme.fontSize.body};
  margin: 5px;
  text-decoration: none;
`;

const Left = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: centler;
`;

const Right = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: centler;
`;

const Center = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: centler;
`;

type Props = {
  header: header;
  contact: {
    title: string;
    linkUrl: string;
    linkText: string;
  }[];
  barVisible?: boolean;
  backButton?: boolean;
};

const Header: FC<Props> = ({
  header,
  contact,
  barVisible = false,
  backButton = false
}) => {
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [reelOpen, setReelOpen] = useState(false);

  useEffect(() => {
    gsap.set(menuRef.current!, {
      x: window.innerWidth
    });
  }, []);

  useEffect(() => {
    if (menuOpen) {
      gsap.to(menuRef.current!, 0.5, {
        x: 0,
        ease: 'power1.inOut'
      });
    } else {
      gsap.to(menuRef.current!, 0.5, {
        x: 500,
        ease: 'power1.inOut'
      });
    }
  }, [menuOpen]);

  const linkClick = (target: string) => {
    location.hash = target;
    setMenuOpen(false);
  };

  const iconLink = (link: string) => {
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  const backClick = () => {
    history.back();
  };

  const openReel = () => {
    setMenuOpen(false);
    setReelOpen(true);
  };

  return (
    <>
      {reelOpen && (
        <ShowreelMobile
          videoLink={header.showreel}
          close={() => setReelOpen(false)}
        />
      )}
      <Overall>
        <Container barVisible={barVisible}>
          <Left>{backButton && <BackButton onClick={backClick} />}</Left>
          <Center></Center>
          <Right>
            <BurgerIcon
              onClick={() => setMenuOpen(!menuOpen)}
              active={menuOpen}
            />
          </Right>
        </Container>
        <Menu ref={menuRef}>
          <Button width={'100%'} onClick={openReel}>
            reel
          </Button>
          <Gap />
          {header.menulabels.map(label => (
            <>
              <StyledLink
                onClick={e => linkClick(label.anchor)}
                to={`/#${label.anchor}`}
              >
                <Button width={'100%'}>{label.title}</Button>
              </StyledLink>
              <Gap />
            </>
          ))}
          <Icons>
            {header.icons.map((icon, index) => (
              <Icon key={`icon${index}`} onClick={() => iconLink(icon.link)}>
                <i className={`${icon.icon}`} />
              </Icon>
            ))}
          </Icons>
          {contact.map(c => (
            <TextContainer href={c.linkUrl} target='_top'>
              {c.linkText}
            </TextContainer>
          ))}
        </Menu>
      </Overall>
    </>
  );
};

export default Header;
